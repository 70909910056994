body{margin: 0}
/* .page{max-width: 1400px; margin: auto;} */

/* .blueBg{background-color: #6177f8;}
.orangeBg{background-color: #ffba6a;}
.pinkBg{background-color: #f55f91;}
.whiteText{color: white;}
.blueText{color: #6177f8;}
.orangeText{color: #ffba6a;}
.greyText{color: #dbcece;} */

/* .yellowGradient{ background: #f8af30; background: -moz-linear-gradient(0deg, #f8af30 30%, #ffc946 100%); background: -webkit-linear-gradient(0deg, #f8af30 0% #ffc946 100%); background: linear-gradient(0deg, #f8af30 0% #ffc946 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8af30",endColorstr="#ffc946",GradientType=1); }
.redGradient{ background: #ff7351; background: -moz-linear-gradient(0deg, #ff7351 30%, #ffb1a2 100%); background: -webkit-linear-gradient(0deg, #ff7351 30%, #ffb1a2 100%); background: linear-gradient(0deg, #ff7351 30%, #ffb1a2 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff7351",endColorstr="#ffb1a2",GradientType=1); }
.greenGradient{ background: #ff7351; background: -moz-linear-gradient(0deg, #ff7351 30%, #ffb1a2 100%); background: -webkit-linear-gradient(0deg, #ff7351 30%, #ffb1a2 100%); background: linear-gradient(0deg, #ff7351 30%, #ffb1a2 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff7351",endColorstr="#ffb1a2",GradientType=1); }
.lightBlueGradient{ background: #ff7351; background: -moz-linear-gradient(0deg, #ff7351 30%, #ffb1a2 100%); background: -webkit-linear-gradient(0deg, #ff7351 30%, #ffb1a2 100%); background: linear-gradient(0deg, #ff7351 30%, #ffb1a2 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff7351",endColorstr="#ffb1a2",GradientType=1); }
.darkBlueGradient{ background: #ff7351; background: -moz-linear-gradient(0deg, #ff7351 30%, #ffb1a2 100%); background: -webkit-linear-gradient(0deg, #ff7351 30%, #ffb1a2 100%); background: linear-gradient(0deg, #ff7351 30%, #ffb1a2 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff7351",endColorstr="#ffb1a2",GradientType=1); } */

.boxShadow{ -webkit-box-shadow: 0px 65px 65px -23px #d4bdd4; -moz-box-shadow: 0px 65px 65px -23px #d4bdd4; box-shadow: 0px 65px 65px -23px #d4bdd4; }
